import * as React from 'react';
import { useTranslation } from "react-i18next";
import { publicationsWithChat } from "../../constants";

type ContactProps = {
    publicationCode: string;
};

export function ContactUs({publicationCode}: ContactProps) {
    const {t} = useTranslation('common');

    return (
        <div className="contact">
            <h2>{t('contact.question')}</h2>

            { // Create separate contact layout for Blick and Sonntagsblick; everything else uses second template (below)
            publicationCode.includes('blick') ?
                 <>
                <div className="contact-text-blick">
                    <p><a 
                        href={t(`contact.blick.answerLinkURL`)} 
                        target="_blank" 
                        rel="noreferrer">{t(`contact.blick.answerLinkLabel`)}</a>
                    </p>
                    <h2>{t(`contact.blick.noAnswer`)}</h2>
                    <b>{t(`contact.blick.callUs`)}</b>{t(`contact.phonenumber.${!!publicationCode ? publicationCode : 'default'}`)}
                    <br />{t(`contact.hours.${!!publicationCode ? publicationCode : 'default'}`)}
                    <p><b>
                        <a
                            href={t(`contact.blick.answerLinkURL`)}
                            target="_blank"
                            rel="noreferrer">{t(`contact.chat.${!!publicationCode ? publicationCode : 'default'}.linkLabel`)}</a>
                    </b>:
                    <br />{t(`contact.chat.${!!publicationCode ? publicationCode : 'default'}.hours`)}   
                    </p>          
                </div>
                </> : 
            
                <>
                <p>{t(`contact.phonenumber.${!!publicationCode ? publicationCode : 'default'}`)}</p>
                <h2 className="contact-header-second">{t('contact.hoursHeader')}</h2>
                <p>{t(`contact.hours.${!!publicationCode ? publicationCode : 'default'}`)}</p>
                {publicationsWithChat.includes(publicationCode) ?
                    <>
                        <h2 className="contact-header-second">
                            <a
                                href={t(`contact.chat.${!!publicationCode ? publicationCode : 'default'}.linkUrl`)}
                                target="_blank"
                                rel="noreferrer">{t(`contact.chat.${!!publicationCode ? publicationCode : 'default'}.linkLabel`)}</a> {t(`contact.chat.${!!publicationCode ? publicationCode : 'default'}.nonLinkLabel`)}
                        </h2>
                        <p className="chat-hours">{t(`contact.chat.${!!publicationCode ? publicationCode : 'default'}.hours`)}</p>
                    </> : null}
                </>
            }
        </div>
    );
}
