import React from 'react';
import { useTranslation } from 'react-i18next';

type FormCompleteProps = {
    formMessage: string;
    onClick: () => void;
};

export function FormComplete({ formMessage, onClick }: FormCompleteProps) {
        const {t} = useTranslation('common');
        const backBtntext = t('form.backButton');
        return <div className="form-complete-message">
            <p>{formMessage}</p>
            <div className="input-button-wrapper">
                <input
                    onClick={onClick}
                    className="input-button"
                    type="submit"
                    value={backBtntext}
                />
            </div>

        </div>;
}
