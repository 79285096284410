import React, {FunctionComponent, SVGProps} from "react";
import {ReactComponent as NoticeOfTerminationIcon} from '../../images/kundigung.svg';
import {ReactComponent as ReportDeliveryProblemIcon} from '../../images/melden-eines-zustellproblems.svg';
import {ReactComponent as AddressChangeIcon} from '../../images/addressanderung.svg';
import {ReactComponent as BlockedTrainersIcon} from '../../images/melden-einer-zustellunterbruch.svg';
import {ReactComponent as ReportDeliveryInterruptionIcon} from '../../images/melden-einer-ferienumleitung.svg';
import {ReactComponent as OrderSubscriptionIcon} from '../../images/abo-bestellung.svg';
import {ReactComponent as InfoSubscriptionInvoiceIcon} from '../../images/auskunft-zu-abo-rechnung.svg';
import {ReactComponent as BookBillingInformationIcon} from '../../images/auskumft-zum-buch-rechnung.svg';
import {ReactComponent as InfoDigitalSubscriptionIcon} from '../../images/auskunft-zum-digitalen-abonnement.svg';
import {ReactComponent as LoginAccessIcon} from '../../images/login-zugriff.svg';
import {ReactComponent as InfoReaderOffersIcon} from '../../images/leseangebote.svg';
import {ReactComponent as FeedbackEditorsIcon} from '../../images/redaktion.svg';
import {useTranslation} from "react-i18next";
import {
    AroundTheSubscriptionSubCategories,
    DigitalProductsSubCategories,
    EditorialSubCategories,
    InvoiceSubCategories,
    NoticeOfTerminationSubCategories,
    ReaderOffersSubCategories,
    SubscriptionOrderSubCategories
} from '../../constants'

type SelectProps = {
    activeSubCategory: string,
    setActiveSubCategory: Function,
    formRef: any,
    publicationCode?: string
};

type SubCategoryConfig = {
    subcategory: AroundTheSubscriptionSubCategories
        | SubscriptionOrderSubCategories
        | NoticeOfTerminationSubCategories
        | InvoiceSubCategories
        | DigitalProductsSubCategories
        | ReaderOffersSubCategories
        | EditorialSubCategories;
    icon: FunctionComponent<SVGProps<SVGSVGElement>>;
    hiddenForPublications: string[];
};

function SubCategorySelection(props: SelectProps & { subCategories: SubCategoryConfig[] }) {
    const {t} = useTranslation('common');

    const filteredSubCategories = props.subCategories.filter(config => !config.hiddenForPublications.includes(props.publicationCode ?? ''));

    if (filteredSubCategories.length === 1) {
        props.setActiveSubCategory(filteredSubCategories[0].subcategory);
    }

    return (
        <div className="category-section-one">
            <h3 className="select-header">{t('subCategories.subCategoryHeader')}</h3>
            <div className="category-container">
                {filteredSubCategories.map((config =>
                    <div
                        className={props.activeSubCategory === config.subcategory ? 'category-wrapper active' : 'category-wrapper'}
                        onClick={() => {
                            props.setActiveSubCategory(config.subcategory)
                        }}>
                        <config.icon className="category-icon"/>
                        <h4>{t('subCategories.' + config.subcategory)}</h4>
                    </div>))}
            </div>
        </div>
    );
}

export const AroundTheSubscriptionSubCategorySelection = (props: SelectProps): JSX.Element => {
    const subCategories: SubCategoryConfig[] = [{
        subcategory: AroundTheSubscriptionSubCategories.ReportDeliveryProblem,
        icon: ReportDeliveryProblemIcon,
        hiddenForPublications: [],
    }, {
        subcategory: AroundTheSubscriptionSubCategories.AddressChange,
        icon: AddressChangeIcon,
        hiddenForPublications: [],
    }, {
        subcategory: AroundTheSubscriptionSubCategories.BlockedTrainers,
        icon: BlockedTrainersIcon,
        hiddenForPublications: ['caminada', 'interview'],
    }, {
        subcategory: AroundTheSubscriptionSubCategories.ReportDeliveryInterruption,
        icon: ReportDeliveryInterruptionIcon,
        hiddenForPublications: [],
    }];

    return <SubCategorySelection {...props} subCategories={subCategories}/>;
}

export const SubscriptionOrderSubCategorySelection = (props: SelectProps): JSX.Element => {
    const subCategories: SubCategoryConfig[] = [{
        subcategory: SubscriptionOrderSubCategories.OrderSubscription,
        icon: OrderSubscriptionIcon,
        hiddenForPublications: [],
    }, {
        subcategory: SubscriptionOrderSubCategories.SubscriptionGiveAway,
        icon: AddressChangeIcon,
        hiddenForPublications: [],
    }];

    return <SubCategorySelection {...props} subCategories={subCategories}/>;
}

export const NoticeOfTerminationSubCategorySelection = (props: SelectProps): JSX.Element => {
    const subCategories: SubCategoryConfig[] = [{
        subcategory: NoticeOfTerminationSubCategories.SubscriptionEndAfterTerm,
        icon: NoticeOfTerminationIcon,
        hiddenForPublications: [],
    }];

    return <SubCategorySelection {...props} subCategories={subCategories}/>;
}

export const InvoiceSubCategorySelection = (props: SelectProps): JSX.Element => {
    const subCategories: SubCategoryConfig[] = [{
        subcategory: InvoiceSubCategories.InfoSubscriptionInvoice,
        icon: InfoSubscriptionInvoiceIcon,
        hiddenForPublications: [],
    }, {
        subcategory: InvoiceSubCategories.BookBillingInformation,
        icon: BookBillingInformationIcon,
        hiddenForPublications: ['blick', 'caminada', 'glueckspost', 'tele', 'tvstar', 'tv4', 'interview', 'tv8', 'pme'],
    }];

    return <SubCategorySelection {...props} subCategories={subCategories}/>;
}

export const DigitalProductsSubCategorySelection = (props: SelectProps): JSX.Element => {
    const subCategories: SubCategoryConfig[] = [{
        subcategory: DigitalProductsSubCategories.InfoDigitalSubscription,
        icon: InfoDigitalSubscriptionIcon,
        hiddenForPublications: [],
    }, {
        subcategory: DigitalProductsSubCategories.LoginAccess,
        icon: LoginAccessIcon,
        hiddenForPublications: [],
    }];

    return <SubCategorySelection {...props} subCategories={subCategories}/>;
}

export const ReaderOffersSubCategorySelection = (props: SelectProps): JSX.Element => {
    const subCategories: SubCategoryConfig[] = [{
        subcategory: ReaderOffersSubCategories.InfoReaderOffers,
        icon: InfoReaderOffersIcon,
        hiddenForPublications: [],
    }];

    return <SubCategorySelection {...props} subCategories={subCategories}/>;
}

export const EditorialSubCategorySelection = (props: SelectProps): JSX.Element => {
    const subCategories: SubCategoryConfig[] = [{
        subcategory: EditorialSubCategories.FeedbackEditors,
        icon: FeedbackEditorsIcon,
        hiddenForPublications: [],
    }];

    return <SubCategorySelection {...props} subCategories={subCategories}/>;
}
