import * as React from 'react';

type HeaderProps = {

};

export function Header(props: HeaderProps) {
    return (
        <header className="header">
            <div className="brandLogo" />
        </header>
    );
}
