import {ReactComponent as AroundTheSubscriptionIcon} from './images/rund-ums-abo.svg';
import {ReactComponent as SubscriptionOrderIcon} from './images/abo-bestellung.svg';
import {ReactComponent as NoticeOfTerminationIcon} from './images/kundigung.svg';
import {ReactComponent as InvoiceIcon} from './images/rechnung.svg';
import {ReactComponent as DigitalProductsIcon} from './images/digitale-produkte.svg';
import {ReactComponent as ReaderOffersIcon} from './images/leseangebote.svg';
import {ReactComponent as EditorialIcon} from './images/redaktion.svg';
import {FunctionComponent, SVGProps} from "react";

export enum Category {
    AroundTheSubscription = "aroundTheSubscription",
    SubscriptionOrder = "subscriptionOrder",
    NoticeOfTermination = "noticeOfTermination",
    Invoice = "invoice",
    DigitalProducts = "digitalProducts",
    ReaderOffers = "readerOffers",
    Editorial = "editorial"
}

export enum AroundTheSubscriptionSubCategories {
    ReportDeliveryProblem = "reportDeliveryProblem",
    AddressChange = "addressChange",
    BlockedTrainers = "blockedTrainers",
    ReportDeliveryInterruption = "reportDeliveryInterruption",
}


export enum SubscriptionOrderSubCategories {
    OrderSubscription = "orderSubscription",
    SubscriptionGiveAway = "subscriptionGiveAway",
}

export enum NoticeOfTerminationSubCategories {
    SubscriptionEndAfterTerm = "subscriptionEndAfterTerm",
}

export enum InvoiceSubCategories {
    InfoSubscriptionInvoice = "infoSubscriptionInvoice",
    BookBillingInformation = "bookBillingInformation",
}

export enum DigitalProductsSubCategories {
    InfoDigitalSubscription = "infoDigitalSubscription",
    LoginAccess = "loginAccess",
}

export enum ReaderOffersSubCategories {
    InfoReaderOffers = "infoReaderOffers",
}

export enum EditorialSubCategories {
    FeedbackEditors = "feedbackEditors",
}

type CategoryConfig = {
    category: Category;
    icon: FunctionComponent<SVGProps<SVGSVGElement>>;
}

export const AroundTheSubscriptionConfig: CategoryConfig = {
    category: Category.AroundTheSubscription,
    icon: AroundTheSubscriptionIcon,
}
export const SubscriptionOrderConfig: CategoryConfig = {
    category: Category.SubscriptionOrder,
    icon: SubscriptionOrderIcon,
}
export const NoticeOfTerminationConfig: CategoryConfig = {
    category: Category.NoticeOfTermination,
    icon: NoticeOfTerminationIcon,
}
export const InvoiceConfig: CategoryConfig = {
    category: Category.Invoice,
    icon: InvoiceIcon,
}
export const DigitalProductsConfig: CategoryConfig = {
    category: Category.DigitalProducts,
    icon: DigitalProductsIcon,
}
export const ReaderOffersConfig: CategoryConfig = {
    category: Category.ReaderOffers,
    icon: ReaderOffersIcon,
}
export const EditorialConfig: CategoryConfig = {
    category: Category.Editorial,
    icon: EditorialIcon,
}

export const PublicationCategories: Record<string, CategoryConfig[]> = {
    'base': [AroundTheSubscriptionConfig, SubscriptionOrderConfig, NoticeOfTerminationConfig, InvoiceConfig, DigitalProductsConfig, ReaderOffersConfig, EditorialConfig],
    'tv8': [AroundTheSubscriptionConfig, SubscriptionOrderConfig, NoticeOfTerminationConfig, InvoiceConfig, ReaderOffersConfig, EditorialConfig],
    'streaming': [EditorialConfig],
    'caminada': [AroundTheSubscriptionConfig, SubscriptionOrderConfig, NoticeOfTerminationConfig, InvoiceConfig, ReaderOffersConfig, EditorialConfig],
    'interview': [AroundTheSubscriptionConfig, SubscriptionOrderConfig, NoticeOfTerminationConfig, InvoiceConfig, EditorialConfig],
    'blick': [AroundTheSubscriptionConfig, SubscriptionOrderConfig, NoticeOfTerminationConfig, InvoiceConfig, DigitalProductsConfig, EditorialConfig],
}

export enum TerminationReasons {
    NO_INTEREST = "noInterest",
    PERSONAL_REASONS = "personalReasons",
    DIED = "died",
    SICK = "sick",
    DOUBLE = "double",
    PRICE_INCREASE = "priceIncrease",
    DEPARTURE_IN_FOREIGN_COUNTRY = "departureInForeignCountry",
    OTHER_MEDIA = "otherMedia",
    DISSATISFIED_CONTENT_EDITORIAL = "dissatisfiedContentEditorial",
    POLITICAL_REASONS = "politicalReasons",
    SECONDARY_READER = "secondaryReader",
    PREFER_DIGITAL_APP = "preferDigitalApp",
    RETENTION = "retention",
    NO_TIME = "noTime"

}

export const terminationReasonList = [
    TerminationReasons.NO_INTEREST,
    TerminationReasons.PERSONAL_REASONS,
    TerminationReasons.DIED,
    TerminationReasons.SICK,
    TerminationReasons.DOUBLE,
    TerminationReasons.PRICE_INCREASE,
    TerminationReasons.DEPARTURE_IN_FOREIGN_COUNTRY,
    TerminationReasons.OTHER_MEDIA,
    TerminationReasons.DISSATISFIED_CONTENT_EDITORIAL,
    TerminationReasons.POLITICAL_REASONS,
    TerminationReasons.SECONDARY_READER,
    TerminationReasons.PREFER_DIGITAL_APP,
    TerminationReasons.RETENTION,
    TerminationReasons.NO_TIME,
];

export const publicationsWithChat = ['blick', 'sonntagsblick'];

export const BackendURL = process.env.REACT_APP_BACKEND_URL ?? '';
export const RecaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY ?? '';
