import React, {useRef} from 'react';
import {
    DigitalProductsSubCategorySelection,
    InvoiceSubCategorySelection,
    AroundTheSubscriptionSubCategorySelection,
    EditorialSubCategorySelection,
    ReaderOffersSubCategorySelection,
    NoticeOfTerminationSubCategorySelection,
    SubscriptionOrderSubCategorySelection
} from '../sub-categories/sub-categories'
import {useTranslation} from "react-i18next";
import {Category, PublicationCategories} from '../../constants'

type SelectProps = {
    activeCategory: string,
    setActiveCategory: Function,
    activeSubCategory: string,
    setActiveSubCategory: Function,
    formRef: any,
    publicationCode: string
};

export function SelectOption(props: SelectProps) {

    const myRef = useRef<null | HTMLDivElement>(null);
    const {t} = useTranslation('common');

    const onClickCategory = (category: string): any => {
        if (category !== props.activeCategory) {
            props.setActiveCategory(category)
            props.setActiveSubCategory('')

            setTimeout(function () {
                myRef.current?.scrollIntoView({block: 'end', behavior: 'smooth'})
            }, 100);
        }
    }

    const CategorySelection = (): JSX.Element => {
        let categories = PublicationCategories[props.publicationCode];
        if (!categories) {
            categories = PublicationCategories.base;
        }
        return (
            <div className="category-section-one">
                <h3 className="select-header">{t('categories.categoryHeader')}</h3>
                <div className="category-container">
                    {categories.map(categoryConfig => <div
                        className={props.activeCategory === categoryConfig.category ? 'category-wrapper active' : 'category-wrapper'}
                        onClick={() => onClickCategory(categoryConfig.category)}>
                        <categoryConfig.icon className="category-icon"/>
                        <h4>{t('categories.' + categoryConfig.category)}</h4>
                    </div>)}
                </div>
            </div>
        )
    }

    const SubCategorySelection = (): JSX.Element => {
        return (
            <div id={'myRef'} ref={myRef} className="category-section-two-wrapper">
                {props.activeCategory === Category.AroundTheSubscription ?
                    <AroundTheSubscriptionSubCategorySelection
                        activeSubCategory={props.activeSubCategory}
                        setActiveSubCategory={props.setActiveSubCategory}
                        formRef={props.formRef}
                        publicationCode={props.publicationCode}
                    />
                    : null
                }
                {props.activeCategory === Category.SubscriptionOrder ?
                    <SubscriptionOrderSubCategorySelection
                        activeSubCategory={props.activeSubCategory}
                        setActiveSubCategory={props.setActiveSubCategory}
                        formRef={props.formRef}
                    />
                    : null
                }
                {props.activeCategory === Category.NoticeOfTermination ?
                    <NoticeOfTerminationSubCategorySelection
                        activeSubCategory={props.activeSubCategory}
                        setActiveSubCategory={props.setActiveSubCategory}
                        formRef={props.formRef}
                    />
                    : null
                }
                {props.activeCategory === Category.Invoice ?
                    <InvoiceSubCategorySelection
                        activeSubCategory={props.activeSubCategory}
                        setActiveSubCategory={props.setActiveSubCategory}
                        formRef={props.formRef}
                        publicationCode={props.publicationCode}
                    />
                    : null
                }
                {props.activeCategory === Category.DigitalProducts ?
                    <DigitalProductsSubCategorySelection
                        activeSubCategory={props.activeSubCategory}
                        setActiveSubCategory={props.setActiveSubCategory}
                        formRef={props.formRef}
                    />
                    : null
                }
                {props.activeCategory === Category.ReaderOffers ?
                    <ReaderOffersSubCategorySelection
                        activeSubCategory={props.activeSubCategory}
                        setActiveSubCategory={props.setActiveSubCategory}
                        formRef={props.formRef}
                    />
                    : null
                }
                {props.activeCategory === Category.Editorial ?
                    <EditorialSubCategorySelection
                        activeSubCategory={props.activeSubCategory}
                        setActiveSubCategory={props.setActiveSubCategory}
                        formRef={props.formRef}
                    />
                    : null
                }
            </div>
        )
    }

    return (
        <div className="select">
            <CategorySelection/>

            {props.activeCategory ?
                <SubCategorySelection/>
                : null}
        </div>
    );
}
