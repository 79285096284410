import React, { useMemo } from 'react';

type SelectProps = {
    label: string;
    options: {
        label: string;
        value: string;
    }[];
    value: string;
    onChange: (value:string) => void
    id?: string;
    name?: string;
    size?: string;
    validation?: boolean;
    formSubmitted?: boolean;
};
export const Select = ({ label, options, value, onChange, id, name, size, validation, formSubmitted }: SelectProps) => {
    let inputWidth;
    switch(size) {
        case 'small':
            inputWidth = "15%";
            break;
        case 'medium':
            inputWidth = "47.5%";
            break;
        case 'large':
            inputWidth = "60%";
            break;
        default:
            inputWidth = "47.5%";
    }
    const inputValidationClassName = useMemo(() => {
        if (validation) {
            return !!value ? 'valid' : '';
        } else {
            return formSubmitted ? 'invalid' : '';
        }
    }, [value, validation, formSubmitted]);
    return (
        <div className="text-input-wrapper" style={{width: inputWidth, marginRight: '5%'}}>
            <label htmlFor={id}>{label}</label>
            <br />
            <div style={{width: '100%',position: 'relative'}}>
                <select name={name} id={id} className={inputValidationClassName}
                        style={{width: '100%', fontWeight: 600, lineHeight: '17px' }}
                        onChange={e => onChange(e.target.value)}>
                    {options.map(option => (
                        <option key={option.value} value={option.value}>{option.label}</option>)
                    )}
                </select>
            </div>
        </div>
    );
};
