import { i18n } from 'i18next';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './App.scss';
import { ContactUs } from './components/contact-us/contact-us';
import { BlickPublicationSelection } from './components/form/blickPublicationSelection';
import { Form } from './components/form/form';
import { FormComplete } from './components/form/formComplete';
import { Header } from './components/header/header';
import { SelectOption } from './components/select-option/select-option';

function App() {
    const formRef = useRef<null | HTMLDivElement>(null);
    const publicationCode = window.location.pathname.substr(window.location.pathname.lastIndexOf('/') + 1).toLowerCase();
    const hideHeader = window.location.search.indexOf('hideHeader=true') !== -1;

    const {t, i18n} = useTranslation('common');

    const [activeCategory, setActiveCategory] = useState('');
    const [activeSubCategory, setActiveSubCategory] = useState('');
    const [formData, setFormData] = useState(Array<any>());
    const [formComplete, setFormComplete] = useState(false);
    const [formMessage, setFormMessage] = useState('')
    const [currentLanguage, setCurrentLanguage] = useState('de')
    const [formPublicationCode, setFormPublicationCode] = useState(publicationCode)

    /**
     * If the contact form is used within an iFrame, we can tell the embedding page
     * the new needed height of the form.
     */
    const onResize = useCallback(
        () => {
            window.parent.postMessage({
                type: 'resize',
                height: document.body.scrollHeight,
            }, '*');
        },
        [],
    );

    useEffect(() => {
        onResize();
        window.addEventListener('resize', onResize);
        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, [onResize]);

    useEffect(onResize, [onResize, activeCategory, activeSubCategory, formComplete, formMessage]);

    const language = detectLanguage(publicationCode, currentLanguage, setCurrentLanguage, i18n);

    const backToForm = () => {
        setFormComplete(false)
        setFormMessage('')
    }

    return (
    <div className={`self-service-root ${publicationCode}${hideHeader ? ' hide-header' : ''}`}>
        {!hideHeader && <Header />}
        {
            formComplete ?
                <FormComplete formMessage={formMessage} onClick={backToForm}/>
            :
                <div className="content">
                    <div className="section-one">
                        <h1>{t('title')}</h1>
                        {publicationCode === 'blick' &&
                            <BlickPublicationSelection formPublicationCode={formPublicationCode} onChange={setFormPublicationCode}/>
                        }
                        <h2 className="context-text-header-two">{t('description')}</h2>
                        <SelectOption
                            formRef={formRef}
                            activeCategory={activeCategory}
                            setActiveCategory={setActiveCategory}
                            activeSubCategory={activeSubCategory}
                            setActiveSubCategory={setActiveSubCategory}
                            publicationCode={publicationCode}
                        />
                        {
                            activeSubCategory ?
                                <>
                                    <Form
                                        activeCategory={activeCategory}
                                        activeSubCategory={activeSubCategory}
                                        setActiveCategory={setActiveCategory}
                                        setActiveSubCategory={setActiveSubCategory}
                                        formData={formData}
                                        setFormData={setFormData}
                                        formRef={formRef}
                                        publicationCode={formPublicationCode}
                                        language={language}
                                        setFormComplete={setFormComplete}
                                        setFormMessage={setFormMessage}
                                        formMessage={formMessage}
                                        onResize={onResize}
                                    />
                                </>
                                : null
                        }
                    </div>
                    <div className="section-two">
                        <ContactUs publicationCode={formPublicationCode} />
                    </div>
                </div>
        }

    </div>
  );
}

export default App;

function detectLanguage(
    publicationCode: string,
    currentLanguage: string,
    setCurrentLanguage: (value: (((prevState: string) => string) | string)) => void,
    i18n: i18n
) {
    let language: string;

    switch (publicationCode) {
        case 'illustre':
            language = 'fr';
            break;
        case 'pme':
            language = 'fr';
            break;
        case 'tv8':
            language = 'fr';
            break;
        default:
            language = 'de';
    }

    if (language !== currentLanguage) {
        setCurrentLanguage(language);
        i18n.changeLanguage(language, (err: any) => {
            if (err) return console.log('something went wrong loading', err);
        });
    }
    return language;
}
