import React from 'react';

type BlickPublicationSelectionProps = {
    formPublicationCode: string;
    onChange: (value: string) => void;
};

export function BlickPublicationSelection({ formPublicationCode, onChange }: BlickPublicationSelectionProps) {
        return <div className="form-publication-code-radio-group-container">
            <div className="form-publication-code-radio-container">
                <input
                    id="blickCheckbox"
                    name="formPublicationCode"
                    type="radio"
                    checked={formPublicationCode === 'blick'}
                    onChange={() => onChange('blick')}/>
                <label htmlFor="blickCheckbox">Blick / Blick+</label>
            </div>
            <div className="form-publication-code-radio-container">
                <input
                    id="sBlickCheckbox"
                    name="formPublicationCode"
                    type="radio"
                    checked={formPublicationCode === 'sonntagsblick'}
                    onChange={() => onChange('sonntagsblick')}/>
                <label htmlFor="sBlickCheckbox">SonntagsBlick</label>
            </div>
        </div>;
}
