import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.scss';
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";
import { RecaptchaKey } from './constants';
import common_de from "./translations/de/common.json";
import common_fr from "./translations/fr/common.json";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

i18next.init({
    interpolation: { escapeValue: false },  // React already does escaping
    lng: 'de',                              // language to use
    resources: {
        de: {
            common: common_de
        },
        fr: {
            common: common_fr
        },
    },
});

ReactDOM.render(
  <React.StrictMode>
      <GoogleReCaptchaProvider
          reCaptchaKey={RecaptchaKey}
          language="de"
          useRecaptchaNet={true}
      >
      <I18nextProvider i18n={i18next}>
          <App/>
      </I18nextProvider>
      </GoogleReCaptchaProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
