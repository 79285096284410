import {
    AroundTheSubscriptionSubCategories,
    DigitalProductsSubCategories,
    EditorialSubCategories,
    InvoiceSubCategories,
    NoticeOfTerminationSubCategories,
    ReaderOffersSubCategories,
    SubscriptionOrderSubCategories
} from "../constants";
import { FormData, FormDataValidated } from "./types";

export const defaultFormDataValidated: FormDataValidated = {
    customerNumber: false,
    firstName: false,
    lastName: false,
    firm: false,
    street: false,
    streetNumber: false,
    zipcode: false,
    city: false,
    emailAddress: false,
    careOf: false,
    mailbox: false,
    phoneNumber: false,
    textArea: false,
    dateFrom: false,
    dateTo: false,
    terminationReason: false,
    additionalFormFirstName: false,
    additionalFormLastName: false,
    additionalFormCareOf: true,
    additionalFormStreet: false,
    additionalFormStreetNumber: false,
    additionalFormZipcode: false,
    additionalFormCity: false,
    additionalFormEmailAddress: false,
    additionalFormPhoneNumber: false,
};

export function checkCustomerNumber(customerNumber: string) {
    const re = /^[0-9]{7,10}$/;
    return re.test(customerNumber) && customerNumber.indexOf('000') !== 0;
}

export function checkEmail(emailToCheck: string) {
    const re = /^[^@\s]+@[^@\s]+\.[^@\s]+$/;
    return re.test(emailToCheck);
}

function checkPostalAddress(formValidated: FormDataValidated) {
    return formValidated.street
        && formValidated.streetNumber
        && formValidated.zipcode
        && formValidated.city
        && checkBasicCustomerData(formValidated);
}

function checkBasicCustomerData(formValidated: FormDataValidated) {
    return formValidated.lastName
        && formValidated.firstName
        && formValidated.firm
        && formValidated.mailbox
        && formValidated.emailAddress
        && formValidated.phoneNumber;
}

function checkAdditionalPostalAddress(formValidated: FormDataValidated) {
    return formValidated.additionalFormLastName
        && formValidated.additionalFormFirstName
        && formValidated.additionalFormStreet
        && formValidated.additionalFormStreetNumber
        && formValidated.additionalFormZipcode
        && formValidated.additionalFormCity;
}

function checkDateRange(formValidated: FormDataValidated) {
    return formValidated.dateFrom
        && formValidated.dateTo;
}

function checkCustomerAddress(formValidated: FormDataValidated) {
    return formValidated.customerNumber
        && checkPostalAddress(formValidated);
}

export function checkValidFormForSubCategory(activeSubCategory: string, formValidated: FormDataValidated) {
    let allFieldsValid = false;
    // Complete Validations
    if (activeSubCategory === AroundTheSubscriptionSubCategories.ReportDeliveryProblem ||
        activeSubCategory === NoticeOfTerminationSubCategories.SubscriptionEndAfterTerm ||
        activeSubCategory === InvoiceSubCategories.InfoSubscriptionInvoice ||
        activeSubCategory === InvoiceSubCategories.BookBillingInformation
    ) {
        allFieldsValid = formValidated.textArea
            && formValidated.terminationReason
            && checkCustomerAddress(formValidated);
    } else if (activeSubCategory === AroundTheSubscriptionSubCategories.AddressChange) {
        allFieldsValid = formValidated.additionalFormEmailAddress
            && formValidated.additionalFormPhoneNumber
            && checkCustomerAddress(formValidated)
            && checkAdditionalPostalAddress(formValidated);
    } else if (activeSubCategory === AroundTheSubscriptionSubCategories.BlockedTrainers) {
        allFieldsValid = checkCustomerAddress(formValidated)
            && checkDateRange(formValidated);
    } else if (activeSubCategory === AroundTheSubscriptionSubCategories.ReportDeliveryInterruption) {
        allFieldsValid = checkCustomerAddress(formValidated)
            && checkDateRange(formValidated)
            && checkAdditionalPostalAddress(formValidated);
    } else if (activeSubCategory === SubscriptionOrderSubCategories.OrderSubscription ||
        activeSubCategory === SubscriptionOrderSubCategories.SubscriptionGiveAway) {
        allFieldsValid = checkPostalAddress(formValidated);
    } else if (activeSubCategory === DigitalProductsSubCategories.InfoDigitalSubscription ||
        activeSubCategory === DigitalProductsSubCategories.LoginAccess) {
        allFieldsValid = formValidated.customerNumber
            && formValidated.textArea
            && checkBasicCustomerData(formValidated);
    } else if (activeSubCategory === ReaderOffersSubCategories.InfoReaderOffers) {
        allFieldsValid = formValidated.textArea
            && checkCustomerAddress(formValidated);
    } else if (activeSubCategory === EditorialSubCategories.FeedbackEditors) {
        allFieldsValid = formValidated.textArea
            && checkPostalAddress(formValidated);
    }
    return allFieldsValid;
}

export function validate(formData: FormData, activeSubCategory: string, publicationCode: string): FormDataValidated {
    const validated: FormDataValidated = {...defaultFormDataValidated};
    validated.customerNumber = true;


    if (activeSubCategory === DigitalProductsSubCategories.InfoDigitalSubscription ||
        activeSubCategory === DigitalProductsSubCategories.LoginAccess ||
        activeSubCategory === ReaderOffersSubCategories.InfoReaderOffers ||
        activeSubCategory === NoticeOfTerminationSubCategories.SubscriptionEndAfterTerm
    ) {
        let customerNumberValid = false;
        if ((publicationCode.includes('blick') && formData.customerNumber === '') || checkCustomerNumber(formData.customerNumber)) customerNumberValid = true;
        validated.customerNumber = customerNumberValid;
    } else if (activeSubCategory !== SubscriptionOrderSubCategories.OrderSubscription &&
        activeSubCategory !== SubscriptionOrderSubCategories.SubscriptionGiveAway &&
        activeSubCategory !== EditorialSubCategories.FeedbackEditors) {
        let customerNumberValid = false;
        if (formData.customerNumber === '' || checkCustomerNumber(formData.customerNumber)) customerNumberValid = true;
        validated.customerNumber = customerNumberValid;
    }

    if (activeSubCategory === SubscriptionOrderSubCategories.OrderSubscription) {
        validated.careOf = true;
    }

    if (activeSubCategory === AroundTheSubscriptionSubCategories.ReportDeliveryProblem ||
        activeSubCategory === AroundTheSubscriptionSubCategories.AddressChange ||
        activeSubCategory === AroundTheSubscriptionSubCategories.BlockedTrainers ||
        activeSubCategory === AroundTheSubscriptionSubCategories.ReportDeliveryInterruption ||
        activeSubCategory === NoticeOfTerminationSubCategories.SubscriptionEndAfterTerm ||
        activeSubCategory === InvoiceSubCategories.InfoSubscriptionInvoice ||
        activeSubCategory === InvoiceSubCategories.BookBillingInformation
    ) {
        if (formData.customerNumber.length > 0) {
            validated.street = true;
            validated.streetNumber = true;
        } else {
            validated.street = formData.street.length > 0;
            validated.streetNumber = formData.streetNumber.length > 0;
        }
    } else {
        validated.street = formData.street.length > 0;
        validated.streetNumber = formData.streetNumber.length > 0;
    }

    validated.lastName = formData.lastName.length > 0;
    validated.firstName = formData.firstName.length > 0;
    validated.firm = true;
    validated.zipcode = formData.zipcode.length > 0;
    validated.city = formData.city.length > 0;
    validated.mailbox = true;
    let emailAddressOrPhoneNumberPresent = formData.emailAddress.length > 0 && formData.phoneNumber.length > 0;
    if (formData.emailAddress.length > 0) {
        validated.emailAddress = checkEmail(formData.emailAddress);
    } else {
        validated.emailAddress = emailAddressOrPhoneNumberPresent;
    }
    validated.phoneNumber = formData.phoneNumber.length > 0;

    // Additional Validations
    if (activeSubCategory === AroundTheSubscriptionSubCategories.ReportDeliveryProblem ||
        activeSubCategory === InvoiceSubCategories.InfoSubscriptionInvoice ||
        activeSubCategory === InvoiceSubCategories.BookBillingInformation ||
        activeSubCategory === DigitalProductsSubCategories.InfoDigitalSubscription ||
        activeSubCategory === DigitalProductsSubCategories.LoginAccess ||
        activeSubCategory === ReaderOffersSubCategories.InfoReaderOffers ||
        activeSubCategory === EditorialSubCategories.FeedbackEditors
    ) {
        validated.textArea = formData.textArea.length > 0;
    }

    if (activeSubCategory === NoticeOfTerminationSubCategories.SubscriptionEndAfterTerm) {
        validated.terminationReason = formData.terminationReason.length > 0;
        validated.textArea = true;
    } else {
        validated.terminationReason = true;
    }

    if (activeSubCategory === AroundTheSubscriptionSubCategories.AddressChange) {
        validated.additionalFormLastName = formData.additionalFormLastName.length > 0;
        validated.additionalFormFirstName = formData.additionalFormFirstName.length > 0;
        validated.additionalFormStreet = formData.additionalFormStreet.length > 0;
        validated.additionalFormStreetNumber = formData.additionalFormStreetNumber.length > 0;
        validated.additionalFormZipcode = formData.additionalFormZipcode.length > 0;
        validated.additionalFormCity = formData.additionalFormCity.length > 0;

        let additionalEmailAddressOrPhoneNumberPresent = formData.additionalFormEmailAddress.length > 0 && formData.additionalFormPhoneNumber.length > 0;
        if (formData.additionalFormEmailAddress.length > 0) {
            validated.additionalFormEmailAddress = checkEmail(formData.additionalFormEmailAddress);
        } else {
            validated.additionalFormEmailAddress = additionalEmailAddressOrPhoneNumberPresent;
        }
        validated.additionalFormPhoneNumber = additionalEmailAddressOrPhoneNumberPresent;
    }

    if (activeSubCategory === AroundTheSubscriptionSubCategories.BlockedTrainers) {
        validated.dateFrom = formData.dateFrom !== '';
        validated.dateTo = formData.dateTo !== '';
    }

    if (activeSubCategory === AroundTheSubscriptionSubCategories.ReportDeliveryInterruption) {
        validated.dateFrom = formData.dateFrom !== '';
        validated.dateTo = formData.dateTo !== '';
        validated.additionalFormLastName = formData.additionalFormLastName.length > 0;
        validated.additionalFormFirstName = formData.additionalFormFirstName.length > 0;
        validated.additionalFormStreet = formData.additionalFormStreet.length > 0;
        validated.additionalFormStreetNumber = formData.additionalFormStreetNumber.length > 0;
        validated.additionalFormZipcode = formData.additionalFormZipcode.length > 0;
        validated.additionalFormCity = formData.additionalFormCity.length > 0;
    }

    return validated;
}