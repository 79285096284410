import React, {useCallback, useEffect, useState} from 'react';
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import {useTranslation} from 'react-i18next';
import {
    AroundTheSubscriptionSubCategories,
    BackendURL,
    DigitalProductsSubCategories,
    EditorialSubCategories,
    InvoiceSubCategories,
    NoticeOfTerminationSubCategories,
    ReaderOffersSubCategories,
    SubscriptionOrderSubCategories,
    terminationReasonList
} from '../../constants';
import {DateInput} from './InputDate';
import {TextInput} from './InputText';
import {TextAreaInput} from './InputTextArea';
import {Select} from './Select';
import {checkValidFormForSubCategory, defaultFormDataValidated, validate} from "../../utils/validation";
import {FormData, FormDataValidated} from "../../utils/types";

type FormProps = {
    activeCategory: string
    activeSubCategory: string
    formData: Array<any>
    setFormData: Function
    setFormComplete: Function
    setFormMessage: Function
    setActiveCategory: Function
    setActiveSubCategory: Function
    formMessage: string
    formRef: any
    publicationCode: any
    language: any
    onResize: Function
};

const defaultFormData: FormData = {
    customerNumber: '',
    firstName: '',
    lastName: '',
    firm: '',
    street: '',
    streetNumber: '',
    zipcode: '',
    city: '',
    emailAddress: '',
    careOf: '',
    mailbox: '',
    phoneNumber: '',
    textArea: '',
    dateFrom: '',
    dateTo: '',
    terminationReason: '',
    additionalFormFirstName: '',
    additionalFormLastName: '',
    additionalFormCareOf: '',
    additionalFormStreet: '',
    additionalFormStreetNumber: '',
    additionalFormZipcode: '',
    additionalFormCity: '',
    additionalFormEmailAddress: '',
    additionalFormPhoneNumber: '',
};

export function Form({
                         activeSubCategory,
                         setFormComplete,
                         setFormMessage,
                         setActiveCategory,
                         setActiveSubCategory,
                         formMessage,
                         formRef,
                         publicationCode,
                         language,
                         onResize
                     }: FormProps) {
    const {t} = useTranslation('common');
    let subHeadline = ''
    let textAreaTitle = ''
    let deliveryInterruptionSubHeadline = ''
    let additionalFormSubHeadline = ''

    // Main form
    const [formSubmitted, setFormSubmitted] = useState(false)
    const [formErrorMessage, setFormErrorMessage] = useState('')
    const [formData, setFormData] = useState<FormData>({...defaultFormData});
    const [formDataValidated, setFormDataValidated] = useState<FormDataValidated>({...defaultFormDataValidated});

    useEffect(() => {
        if (!!onResize) {
            onResize();
        }
    }, [onResize, formErrorMessage]);

    const onFieldChange = useCallback((field: keyof FormData) => (value: string) => setFormData((prevState: FormData) => ({
        ...prevState,
        [field]: value
    })), []);


    const {executeRecaptcha} = useGoogleReCaptcha();
    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            return;
        }

        return executeRecaptcha('contactformUser');
    }, [executeRecaptcha]);

    const submitBtntext = t('form.submitButton')

    const submitForm = async (e: React.FormEvent): Promise<void> => {
        e.preventDefault();
        const token = await handleReCaptchaVerify();

        setFormSubmitted(true)
        const formValidated = validate(formData, activeSubCategory, publicationCode);
        setFormDataValidated(formValidated);

        const formType = activeSubCategory === EditorialSubCategories.FeedbackEditors ? 'editors' : 'common'

        const data = {
            formType,
            "subject": t('subCategories.' + activeSubCategory),
            "contents": formData,
            "publicationCode": publicationCode,
            "publicationSubject": t('mail.subjectPublication.' + publicationCode),
            "language": language,
            token,
        }

        setTimeout(function () {
            if (checkValidFormForSubCategory(activeSubCategory, formValidated)) {
                sendForm(data, setFormMessage)
                setFormErrorMessage('');
            } else {
                setFormErrorMessage(t('form.validateFormError'));
            }
        }, 100);
    }

    const sendForm = (data: {
        formType: string;
        subject: string;
        publicationSubject: string;
        contents: {
            customerNumber: string;
            firstName: string;
            lastName: string;
            firm: string;
            street: string;
            streetNumber: string;
            zipcode: string;
            city: string;
            emailAddress: string;
            careOf: string;
            mailbox: string;
            phoneNumber: string;
            textArea: string;
            dateFrom: string;
            dateTo: string;
            additionalFormFirstName: string;
            additionalFormLastName: string;
            additionalFormCareOf: string;
            additionalFormStreet: string;
            additionalFormStreetNumber: string;
            additionalFormZipcode: string;
            additionalFormCity: string;
            additionalFormEmailAddress: string;
            additionalFormPhoneNumber: string
        };
        publicationCode: any;
        language: any
    }, setFormMessage: Function) => {
        // send form data
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        };
        fetch(BackendURL, requestOptions)
            .then(response => {
                if (response.status === 200) {
                    setFormComplete(true)
                    setFormMessage(t('form.submitFormSuccess'))
                    resetForm()
                } else {
                    setFormMessage(t('form.submitFormError'))
                }
            }).catch(() => {
            setFormMessage(t('form.submitFormError'))
        })
    }

    const resetForm = (): void => {
        setActiveCategory('')
        setActiveSubCategory('')
        setFormSubmitted(false)
        setFormData({...defaultFormData});
        setFormDataValidated({...defaultFormDataValidated});
    }

    if (activeSubCategory === AroundTheSubscriptionSubCategories.AddressChange) {
        subHeadline = t('form.oldAddress')
        additionalFormSubHeadline = t('form.newAddress')
    } else if (activeSubCategory === SubscriptionOrderSubCategories.SubscriptionGiveAway) {
        subHeadline = t('form.myAddress')
        additionalFormSubHeadline = t('form.beschenkter')
    } else if (activeSubCategory === AroundTheSubscriptionSubCategories.ReportDeliveryInterruption) {
        additionalFormSubHeadline = t('form.holidayAddress')
    }

    if (activeSubCategory === AroundTheSubscriptionSubCategories.ReportDeliveryProblem) {
        textAreaTitle = t('form.describeYourProblem')
    } else if (activeSubCategory === NoticeOfTerminationSubCategories.SubscriptionEndAfterTerm) {
        textAreaTitle = t('form.requestExpiringSubscription')
    } else if (activeSubCategory === InvoiceSubCategories.InfoSubscriptionInvoice || activeSubCategory === InvoiceSubCategories.BookBillingInformation) {
        textAreaTitle = t('form.requestAboutInvoice')
    } else if (activeSubCategory === DigitalProductsSubCategories.InfoDigitalSubscription) {
        textAreaTitle = t('form.requestDigitalOffer')
    } else if (activeSubCategory === DigitalProductsSubCategories.LoginAccess) {
        textAreaTitle = t('form.pleaseDescribeRequest')
    } else if (activeSubCategory === ReaderOffersSubCategories.InfoReaderOffers) {
        textAreaTitle = t('form.questionAboutReaderOffer')
    } else if (activeSubCategory === EditorialSubCategories.FeedbackEditors) {
        textAreaTitle = t('form.requestEditorial')
    }

    if (activeSubCategory === AroundTheSubscriptionSubCategories.BlockedTrainers) {
        deliveryInterruptionSubHeadline = t('form.deliveryInterruption')
    }

    const customerNumberChange = (value: string) => {
        const re = /^[0-9\b]+$/;
        if (value === '' || re.test(value)) onFieldChange('customerNumber')(value)
    }

    const onTerminationReasonChange = (value: string) => {
        onFieldChange('terminationReason')(t(`form.terminationReason.${value}`));
    }

    return (
        <div id={'formRef'} ref={formRef} className="form-inputs">
            <h2>{subHeadline}</h2>
            <form>
                {activeSubCategory !== SubscriptionOrderSubCategories.OrderSubscription && activeSubCategory !== SubscriptionOrderSubCategories.SubscriptionGiveAway && activeSubCategory !== EditorialSubCategories.FeedbackEditors ?
                    <TextInput
                        label={t(`form.${publicationCode.includes('blick') ? 'customerSubscriptionNumberOptional' : 'customerSubscriptionNumber'}`)}
                        size='large'
                        name='customerNumber'
                        value={formData.customerNumber}
                        onChange={customerNumberChange}
                        validation={formDataValidated.customerNumber}
                        formSubmitted={formSubmitted}
                    />
                    : null
                }
                <br/>
                <div className="form-input-wrapper">
                    <TextInput
                        label={t('form.firstName')}
                        size='medium'
                        name='name'
                        value={formData.firstName}
                        onChange={onFieldChange('firstName')}
                        validation={formDataValidated.firstName}
                        formSubmitted={formSubmitted}
                    />
                    <TextInput
                        label={t('form.lastName')}
                        size='medium'
                        name='lastName'
                        value={formData.lastName}
                        onChange={onFieldChange('lastName')}
                        validation={formDataValidated.lastName}
                        formSubmitted={formSubmitted}
                    />
                </div>
                {activeSubCategory === SubscriptionOrderSubCategories.OrderSubscription ?
                    <TextInput
                        label={t('form.additionalNameCareOf')}
                        size='medium'
                        name='careOf'
                        value={formData.careOf}
                        onChange={onFieldChange('careOf')}
                        validation={formDataValidated.careOf}
                        formSubmitted={formSubmitted}
                    />
                    : null}
                <TextInput
                    label={t('form.firm')}
                    size='large'
                    name='firm'
                    value={formData.firm}
                    onChange={onFieldChange('firm')}
                    validation={formDataValidated.firm}
                    formSubmitted={formSubmitted}
                />
                {activeSubCategory === DigitalProductsSubCategories.InfoDigitalSubscription || activeSubCategory === DigitalProductsSubCategories.LoginAccess ? null :
                    <>
                        <div className="form-input-wrapper">
                            <TextInput
                                label={t('form.street')}
                                size='medium'
                                name='street'
                                value={formData.street}
                                onChange={onFieldChange('street')}
                                validation={formDataValidated.street}
                                formSubmitted={formSubmitted}
                            />
                            <TextInput
                                label={t('form.streetNumber')}
                                size='small'
                                name='streetNumber'
                                value={formData.streetNumber}
                                onChange={onFieldChange('streetNumber')}
                                validation={formDataValidated.streetNumber}
                                formSubmitted={formSubmitted}
                            />
                        </div>
                        <div className="form-input-wrapper">
                            <TextInput
                                label={t('form.zip')}
                                size='small'
                                name='zipcode'
                                value={formData.zipcode}
                                onChange={onFieldChange('zipcode')}
                                validation={formDataValidated.zipcode}
                                formSubmitted={formSubmitted}
                            />
                            <TextInput
                                label={t('form.city')}
                                size='medium'
                                name='city'
                                value={formData.city}
                                onChange={onFieldChange('city')}
                                validation={formDataValidated.city}
                                formSubmitted={formSubmitted}
                            />
                        </div>
                    </>
                }
                <TextInput
                    label={t('form.poBox')}
                    size='medium'
                    name='mailbox'
                    value={formData.mailbox}
                    onChange={onFieldChange('mailbox')}
                    validation={formDataValidated.mailbox}
                    formSubmitted={formSubmitted}
                />
                <div className="form-input-wrapper">
                    <TextInput
                        label={t('form.email')}
                        size='medium'
                        name='email'
                        value={formData.emailAddress}
                        onChange={onFieldChange('emailAddress')}
                        validation={formDataValidated.emailAddress}
                        formSubmitted={formSubmitted}
                    />
                    <TextInput
                        label={t('form.phoneNumber')}
                        size='medium'
                        name='phoneNumber'
                        value={formData.phoneNumber}
                        onChange={onFieldChange('phoneNumber')}
                        validation={formDataValidated.phoneNumber}
                        formSubmitted={formSubmitted}
                    />
                </div>
                {activeSubCategory === NoticeOfTerminationSubCategories.SubscriptionEndAfterTerm &&
                    <Select
                        label={t('form.terminationReason.label')}
                        options={[{
                            label: '',
                            value: ''
                        }].concat(terminationReasonList.filter(terminationReason => {
                            // If termination reason translation is blank, don't include it in the dropdown list.
                            let reason = t(`form.terminationReason.${terminationReason}`);
                            if(reason === '') return false;
                            return true;    
                        }).map(terminationReason => {

                            return {
                                label: t(`form.terminationReason.${terminationReason}`),
                                value: terminationReason
                            };
                        }).sort(
                            (terminationReason1,
                             terminationReason2) =>
                                terminationReason1.label.localeCompare(terminationReason2.label)
                        ))}
                        value={formData.terminationReason}
                        onChange={onTerminationReasonChange}
                        id="terminationReason"
                        name="terminationReason"
                        size="small"
                        validation={formDataValidated.terminationReason}
                        formSubmitted={formSubmitted}/>
                }
                {textAreaTitle !== '' ?
                    <TextAreaInput
                        label={textAreaTitle}
                        size='medium'
                        name='textArea'
                        value={formData.textArea}
                        onChange={onFieldChange('textArea')}
                        validation={formDataValidated.textArea}
                        formSubmitted={formSubmitted}
                    />
                    : null}
                {activeSubCategory === AroundTheSubscriptionSubCategories.BlockedTrainers || activeSubCategory === AroundTheSubscriptionSubCategories.ReportDeliveryInterruption ?
                    <div>
                        <h2>{deliveryInterruptionSubHeadline}</h2>
                        <div className="form-input-wrapper">
                            <DateInput
                                label={t('form.dateFrom')}
                                size='medium'
                                name='dateFrom'
                                value={formData.dateFrom}
                                onChange={onFieldChange('dateFrom')}
                                validation={formDataValidated.dateFrom}
                                formSubmitted={formSubmitted}
                            />
                            <DateInput
                                label={t('form.dateTo')}
                                size='medium'
                                name='dateTo'
                                value={formData.dateTo}
                                onChange={onFieldChange('dateTo')}
                                validation={formDataValidated.dateTo}
                                formSubmitted={formSubmitted}
                            />
                        </div>
                    </div>
                    : null}
                {activeSubCategory === AroundTheSubscriptionSubCategories.BlockedTrainers && (publicationCode === 'blick' || 
                publicationCode === 'sonntagsblick') ?
                    <div>
                    {t('blickremark')}
                    </div>
                    : null}     
                {additionalFormSubHeadline !== '' ?
                    <>
                        <h2>{additionalFormSubHeadline}</h2>
                        <div className="form-input-wrapper">
                            <TextInput
                                label={t('form.lastName')}
                                size='medium'
                                name='additionalFormLastName'
                                value={formData.additionalFormLastName}
                                onChange={onFieldChange('additionalFormLastName')}
                                validation={formDataValidated.additionalFormLastName}
                                formSubmitted={formSubmitted}
                            />
                            <TextInput
                                label={t('form.firstName')}
                                size='medium'
                                name='additionalFormName'
                                value={formData.additionalFormFirstName}
                                onChange={onFieldChange('additionalFormFirstName')}
                                validation={formDataValidated.additionalFormFirstName}
                                formSubmitted={formSubmitted}
                            />
                        </div>
                        <TextInput
                            label={t('form.additionalNameCareOf')}
                            size='medium'
                            name='additionalCareOf'
                            value={formData.additionalFormCareOf}
                            onChange={onFieldChange('additionalFormCareOf')}
                            validation={formDataValidated.additionalFormCareOf}
                            formSubmitted={formSubmitted}
                        />
                        <div className="form-input-wrapper">
                            <TextInput
                                label={t('form.street')}
                                size='medium'
                                name='additionalFormStreet'
                                value={formData.additionalFormStreet}
                                onChange={onFieldChange('additionalFormStreet')}
                                validation={formDataValidated.additionalFormStreet}
                                formSubmitted={formSubmitted}
                            />
                            <TextInput
                                label={t('form.streetNumber')}
                                size='small'
                                name='addtionalFormStreetNumber'
                                value={formData.additionalFormStreetNumber}
                                onChange={onFieldChange('additionalFormStreetNumber')}
                                validation={formDataValidated.additionalFormStreetNumber}
                                formSubmitted={formSubmitted}
                            />
                        </div>
                        <div className="form-input-wrapper">
                            <TextInput
                                label={t('form.zip')}
                                size='small'
                                name='additionalFormZipcode'
                                value={formData.additionalFormZipcode}
                                onChange={onFieldChange('additionalFormZipcode')}
                                validation={formDataValidated.additionalFormZipcode}
                                formSubmitted={formSubmitted}
                            />
                            <TextInput
                                label={t('form.city')}
                                size='medium'
                                name='additionalFormCity'
                                value={formData.additionalFormCity}
                                onChange={onFieldChange('additionalFormCity')}
                                validation={formDataValidated.additionalFormCity}
                                formSubmitted={formSubmitted}
                            />
                        </div>
                        {activeSubCategory === AroundTheSubscriptionSubCategories.AddressChange ?
                            <div className="form-input-wrapper">
                                <TextInput
                                    label={t('form.email')}
                                    size='medium'
                                    name='additionalFormEmailAddress'
                                    value={formData.additionalFormEmailAddress}
                                    onChange={onFieldChange('additionalFormEmailAddress')}
                                    validation={formDataValidated.additionalFormEmailAddress}
                                    formSubmitted={formSubmitted}
                                />
                                <TextInput
                                    label={t('form.phoneNumber')}
                                    size='medium'
                                    name='additionalFormPhoneNumber'
                                    value={formData.additionalFormPhoneNumber}
                                    onChange={onFieldChange('additionalFormPhoneNumber')}
                                    validation={formDataValidated.additionalFormPhoneNumber}
                                    formSubmitted={formSubmitted}
                                />
                            </div>
                            : null}
                    </>
                    : null}
                <br/>
                <div className="input-button-wrapper">
                    {formErrorMessage && <div className="form-error-message">{formErrorMessage}</div>}
                    <input
                        onClick={submitForm}
                        className="input-button"
                        type='submit'
                        value={submitBtntext}
                    />
                    <p>{formMessage}</p>
                </div>
            </form>
        </div>
    );
}
